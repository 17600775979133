<script setup lang="ts">
import type { VoixLinkField } from '@logicbomb-media/voix/dist/runtime/components/fields/link/types'

defineProps<{
  fields: {
    title: {
      value: string
    }
    subtitle: {
      value: string
    }
    copy: {
      value: string
    }
    link: {
      value: VoixLinkField
      enabled: boolean
    }
    showDoodle: {
      value: boolean
    }
  }
}>()

const target = ref(null)
const { isOutside } = useMouseInElement(target)
defineSlice({ name: { label: 'Paranza Title Hero', group: 'paranza' }, fields: { title: { type: 'text', label: 'Title', name: 'title' }, subtitle: { type: 'text', label: 'Subtitle', name: 'subtitle' }, copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' }, link: { type: 'link', label: 'Link', enabled: false, name: 'link' }, showDoodle: { type: 'checkbox', label: 'Show Doodle', default: true, name: 'showDoodle' } }, slots: [], preview: 'TitleHero.jpg' })
</script>

<template>
  <div class="overflow-hidden max-w-screen px-8 py-12 lg:pt-40">
    <div class="relative container max-w-5xl lg:min-h-[400px] pb-24 lg:pb-0">
      <RestaurantMicroChromeTitle :title="fields.title.value" :subtitle="fields.subtitle.value" />
      <VoixWysiwyg
        v-if="fields.copy.value" :field="fields.copy"
        class="mt-3 prose prose-lg prose-zinc leading-loose font-light"
      />
      <div v-if="fields.link.enabled" ref="target">
        <VoixLink :field="fields.link.value" class="duration-500 ease-out inline-flex flex-col md:flex-row md:items-center md:space-x-4" :class="{ 'translate-x-4': !isOutside }">
          <div class="text-xl uppercase font-light">
            {{ fields.link.value?.text }}
          </div>
          <div class="flex justify-end">
            <RestaurantMicroChromeParanzaArrow class="text-paranza-400 h-6 w-16" />
          </div>
        </VoixLink>
      </div>
      <RestaurantMicroChromeParanzaDoodle
        v-if="fields.showDoodle.value"
        class="absolute bottom-0 lg:bottom-auto lg:top-0 right-0 lg:translate-x-12 text-paranza-400 w-64 h-40 lg:w-auto lg:h-auto"
      />
    </div>
  </div>
</template>
